.section__container--element {
  border-bottom: 1px solid var(--color-dark-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border-left: 0px solid transparent;
  padding: 1rem 0 1rem 0rem;
  overflow: hidden;

  &-link {
    text-decoration: none;
  }

  &--right {
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    transition: all 0.2s ease;
  }

  &--text {
    color: var(--color-text-secondary);
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0.2rem;

    &--date {
      color: var(--color-text-secondary);
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  }
}
.bg-darkpink {
  background-color: #f0abfc;
}
.bg-teal {
  background-color: #15aabf;
}
.bg-lightgreen {
  background-color: #b0dc2d;
}
.bg-yellowishgolden {
  background-color: #f6bf60;
}
.bg-lightpink {
  background-color: #ebccd2;
}
.bg-skills {
  background-color: var(--color-nav-hover);
}

.u-margin-bottom-extra-small {
  margin-bottom: 0.4rem;
}
.heading__tertiary {
  color: var(--color-text-primary);
}

.section__logo {
  height: 5rem;
  transform: rotate(0) scale(1) translate(0);
}

.borderBottomNone {
  border-bottom: none;
}
