.header {
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid var(--color-dark-gray);
  @media (max-width: 425px) {
    padding: 0.6rem 0rem;

    background: var(--transparent);
    -webkit-backdrop-filter: blur(40px) saturate(200%);
    backdrop-filter: blur(40px) saturate(200%);

    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.6rem;
    background: linear-gradient(
      to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-tertiary)
    );
    @media (max-width: 425px) {
      height: 1rem;
    }
  }
}

.nav {
  max-width: 782px;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.6px;
  padding: 2rem 0rem;
  max-width: 782px;
  margin: 0 auto;
  z-index: 999;

  &-list {
    list-style: none;
    display: flex;
    gap: 2rem;
  }

  &-item {
    letter-spacing: 1px;
    position: relative;
    padding: 0 0.4rem;
  }

  &-theme-button {
    background-color: transparent;
    // border: none;
    // height: 100%;

    border: 2px solid var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    height: 4.8rem;
    width: 4.8rem;
    cursor: pointer;

    &:hover {
      transition: all 0.3s ease;
      background: var(--color-text-primary);
    }

    &:hover > &--svg {
      color: var(--color-background);
    }
    &--svg {
      // height: 3.6rem;
      // border: 2px solid var(--color-primary);
      // border-radius: 0.8rem;

      padding: 18%;
      color: var(--color-text-primary);
      background: linear-gradient(
        to bottom,
        rgba(var(--color-background), 0.4),
        rgba(var(--color-text-secondary), 0.3)
      );
      box-shadow: 0 1rem 2rem rgba(var(--color-text-secondary), 0.2);

      @media (max-width: 425px) {
        height: 4.8rem;
      }
    }
  }
}

.link-active {
  color: var(--color-text-primary);
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1.8px;
    background-color: var(--color-primary) !important;
  }
}

.link-inactive {
  color: var(--color-text-secondary);
  text-decoration: none;
}
.navigation__button {
  background-color: transparent;
  // border: none;
  // height: 100%;

  border: 2px solid var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  height: 4.8rem;
  width: 4.8rem;

  cursor: pointer;

  &:hover {
    transition: all 0.3s ease;
    background: var(--color-text-primary);
  }

  &:hover > .navigation__theme--svg {
    color: var(--color-background);
  }
}

.navigation__theme--svg {
  // height: 3.6rem;
  // border: 2px solid var(--color-primary);
  // border-radius: 0.8rem;
  padding: 18%;
  color: var(--color-text-primary);
  background: linear-gradient(
    to bottom,
    rgba(var(--color-background), 0.4),
    rgba(var(--color-text-secondary), 0.3)
  );
  box-shadow: 0 1rem 2rem rgba(var(--color-text-secondary), 0.2);

  @media (max-width: 425px) {
    height: 4.8rem;
  }
}
.navigation__items--mobile {
  list-style: none;
  font-size: 1.6rem;
  font-weight: 400;
}
.navigation__item--mobile {
  padding: 1rem 2rem;
  color: var(--color-text-primary);
  &:hover {
    border-radius: 0.4rem;
    background-color: var(--color-nav-hover);
  }
}

.navigation__item--mobile a {
  text-decoration: none;
  color: var(--color-text-primary);
}
.not-selected {
  text-decoration: none;
}
.selected {
  text-decoration: none;
}

.selected > .navigation__item--mobile {
  color: var(--color-primary) !important;
  font-weight: 800 !important;
  border-radius: 0.4rem;
  background-color: var(--color-nav-hover);
}

.navigation__item--mobile:not(:last-child) {
  margin-bottom: 0.6rem;
}
.navigation__buttons-left-container-name {
  transition: all 120ms ease-out 0s;
  color: var(--color-text-primary);
  letter-spacing: 1px;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border-left: 0px solid var(--color-primary);
  line-height: 1.3;
  &:hover {
    border-left: 4px solid var(--color-primary);
    background-color: var(--color-nav-hover);
  }
}
.navigation__buttons-left-container-name .name {
  font-weight: 800;
  font-size: 1.4rem;
}
.navigation__buttons-left-container-name .job {
  font-weight: 500;
  font-size: 1.3rem;
}

//Animations
