.footer {
  max-width: 782px;
  margin: 0 auto;
  padding: 6rem 0;

  @media (max-width: 769px) {
    width: 90%;
  }
  &__grid {
    display: grid;
    justify-content: start;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 1rem;
    margin-bottom: 8rem;
    &--element {
      font-size: 1.3rem;
      font-weight: 600;
      &-link {
        transition: all 0.3s ease;
        text-decoration: none;
        color: var(--color-text-secondary);
        padding: 0.6rem 1rem 0.6rem 0rem;
        border-left: 4px solid transparent;
        &:hover {
          padding: 0.6rem 1rem 0.6rem 1rem;
          border-left: 4px solid var(--color-primary);
          background-color: var(--color-primary-light);
          cursor: pointer;
        }
      }
    }
  }
  &__name-and-logo {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 1rem 1rem 0.6rem 0rem;
    border-left: 4px solid transparent;
    // width: 35%;
    &:hover {
      padding: 1rem 1rem 0.6rem 1rem;
      border-left: 4px solid var(--color-primary);
      background-color: var(--color-primary-light);
      cursor: pointer;
    }
    margin-bottom: 1.6rem;
  }
  &__name-box {
    transform: translateY(-0.4rem);
    color: var(--color-text-secondary);
    line-height: 1.5;
    letter-spacing: 1px;

    &-name {
      font-size: 1.4rem;
      font-weight: 800;
    }
    &-job {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  &__reach {
    display: flex;
    // width: 35%;
    // justify-content: space-around;
    &-icon {
      transition: all 0.2s ease;
      text-decoration: none;
      color: var(--color-text-secondary);
      padding: 0.2rem 0.8rem 0.15rem 0.8rem;
      border-radius: 0.8rem;
      &:hover {
        transform: scale(1.2);
        color: var(--color-primary-dark);
        background-color: var(--color-primary-light);
      }
      margin-right: 2rem;
    }
  }
  &__details-and-reset {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.btn--reset {
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 0.6rem;
  color: var(--color-text-primary);
  background-color: var(--color-dark-gray);
  cursor: pointer;
  @media (max-width: 320px) {
    font-size: 1.3rem;
  }
}
.header__svg--small {
  background-color: var(--color-primary);
  height: 4.5rem;
  border-radius: 1.4rem;
  transform: rotate(-5deg);
}
.header__svg--small .shadow {
  fill: var(--color-background);
}
