.contact {
  max-width: calc(1080px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 20rem 0 15rem 0;
  border-bottom: 1px solid var(--color-dark-gray);

  @media (max-width: 625px) {
    flex-direction: column;
    justify-content: center;
    padding: 15rem 0 10rem 0;
  }
  @media (max-width: 425px) {
    gap: 4rem;
  }

  &__right {
    width: 50%;
    padding: 2rem 2rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.08);
    border-radius: 1rem;
    border: 1px solid var(--color-dark-gray);
    @media (max-width: 769px) {
      width: 70%;
      margin: 0 auto;
    }
    @media (max-width: 625px) {
      width: 80%;
      margin: 0 auto;
    }
    @media (max-width: 425px) {
      width: 95%;
      margin: 0 auto;
    }

    &--input {
      color: var(--color-text-primary);
      display: block;
      width: 100%;
      padding: 1.5rem 1rem;
      font-family: inherit;
      background-color: var(--color-nav-hover);
      border: none;
      border-radius: 0.6rem;
      letter-spacing: 1.5px;

      &::placeholder{
        color: var(--color-text-secondary);
      }
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &:focus {
        outline: 2px solid var(--color-primary);
      }
    }
    &--button {
      font-size: 1.6rem;
      font-weight: 600;
      width: 100%;
      padding: 1.5rem 1rem;
      font-family: inherit;
      border-radius: 0.6rem;
      border: none;
      color: var(--color-text-primary);
      background-color: var(--color-primary);
      cursor: pointer;
    }
    &--text {
      font-size: 1.8rem;
      color: var(--color-text-secondary);
      margin-bottom: 3rem;
    }
  }
}
.header__svg--small {
  background-color: var(--color-primary);
  height: 4.5rem;
  border-radius: 1.4rem;
  transform: rotate(-5deg);
}
.header__svg--small .shadow {
  fill: var(--color-background);
}
.heading__secondary {
  color: var(--color-text-primary);
  font-size: 3rem;
  letter-spacing: 0px;
}
