.bio {
  color: var(--color-text-primary);

  &__heading {
    text-align: center;
  }

  &__profile {
    &--image {
      height: 20rem;
      width: 20rem;
      border-radius: 100%;
      border: 2px solid var(--color-primary);

      @media (max-width: 425px) {
        margin-bottom: 3rem;
      }
    }
  }

  & table {
    border-spacing: 0 2rem;
  }

  & td {
    vertical-align: text-top;

    &:nth-of-type(1) {
      font-weight: 500;
    }

    &:nth-of-type(2) {
      color: var(--color-text-secondary);
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    .button--download {
      font-size: 1.3rem;
      display: inline-block;
      padding: 0.6rem 1rem;
      background-color: var(--color-primary);
      color: var(--color-text-primary);
      border-radius: 0.5rem;
      text-decoration: none;
      font-weight: 500;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(1);
      }
    }
  }
}
.header__svg {
  background-color: transparent;
  height: 7rem;
  border-radius: 1.8rem;
  transform: rotate(-5deg);
}

.header__svg .shadow {
  fill: var(--color-background);
}

.heading__primary {
  color: var(--color-text-primary);
  font-size: 4.5rem;
  font-weight: 900;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.heading__secondary {
  color: var(--color-text-primary);
  font-size: 3rem;
  margin-bottom: 0.4rem;
  font-weight: 800;
  letter-spacing: 1px;
  word-spacing: -3px;
  margin-bottom: 3rem;
}
