.footer {
  //   max-width: 782px;
  //   margin: 0 auto;
  //   padding: 6rem 0;

  //   @media (max-width: 769px) {
  //     width: 90%;
  //   }

  &__name-and-logo {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 1rem 1rem 0.6rem 0rem;
    border-left: 4px solid transparent;
    // width: 35%;
    &:hover {
      padding: 1rem 1rem 0.6rem 1rem;
      border-left: 4px solid var(--color-primary);
      background-color: var(--color-primary-light);
    }
    margin-bottom: 1.6rem;
  }
  &__name-box {
    transform: translateY(-0.4rem);
    color: var(--color-text-secondary);
    line-height: 1.5;
    letter-spacing: 1px;

    &-name {
      font-size: 1.4rem;
      font-weight: 800;
    }
    &-job {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}

.header__svg--small {
  background-color: var(--color-primary);
  height: 4.5rem;
  border-radius: 1.4rem;
  transform: rotate(-5deg);
}
.header__svg--small .shadow {
  fill: var(--color-background);
}
